import React, { useEffect, useState } from "react";
import { Grid, TextField, IconButton, Button, Typography } from "@mui/material";
import { Add as AddIcon, Cancel as CancelIcon } from "@mui/icons-material";
import { Save } from "@material-ui/icons";

const KeyValuePairsGrid = ({
  title = "Pair",
  pairs,
  setPairs,
  field1Title = "Field 1",
  field2Title = "Field 2",
  keyTitle = "key",
  valueTitle = "value",
  file, //file is an array of objects of key value pairs
  setFile,
}) => {
  const [invalidInput, setInvalidInput] = useState(false);

  const handleAddPair = () => {
    setPairs([...pairs, { [keyTitle]: "", [valueTitle]: "" }]);
  };

  const handleRemovePair = (index) => {
    const newPairs = [...pairs];
    newPairs.splice(index, 1);
    setPairs(newPairs);
  };

  const handlePairChange = (index, field, value) => {
    const newPairs = [...pairs];
    newPairs[index][field] = value;
    setPairs(newPairs);
  };

  const handleSave = () => {
    setFile(pairs);
  };

  useEffect(() => {
    if (file && file.length > 0) {
      //check if the file is an array of objects of key value pairs
      if (
        !Array.isArray(file) ||
        !file.every((obj) => typeof obj === "object")
      ) {
        setInvalidInput(true);
        return;
      }

      const transformedPairs = file.map((obj) => {
        const [keyFile, valueFile] = Object.values(obj);
        return { [keyTitle]: keyFile, [valueTitle]: valueFile };
      });

      setPairs(transformedPairs);
      setInvalidInput(false);
    }
  }, [file, setPairs, keyTitle, valueTitle]);

  return (
    <Grid container direction="column" spacing={2}>
      {pairs.map((pair, index) => (
        <Grid item key={index} container spacing={1}>
          <Grid item xs={5}>
            <TextField
              label={field1Title || "Key"}
              value={pair[keyTitle] || ""}
              onChange={(e) =>
                handlePairChange(index, keyTitle, e.target.value)
              }
              fullWidth
              InputLabelProps={{ shrink: !!pair[keyTitle] }}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label={field2Title || "Value"}
              value={pair[valueTitle] || ""}
              onChange={(e) =>
                handlePairChange(index, valueTitle, e.target.value)
              }
              fullWidth
              InputLabelProps={{ shrink: !!pair[valueTitle] }}
            />
          </Grid>
          {index > 0 && (
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
              }}
            >
              <IconButton onClick={() => handleRemovePair(index)} sx={{}}>
                <CancelIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}
      <Grid item container direction="row" spacing={1}>
        <Grid item xs={5}>
          <Button
            onClick={handleAddPair}
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Add {title}
          </Button>
        </Grid>

        <Grid item xs={5}>
          <Button onClick={handleSave} variant="outlined" startIcon={<Save />}>
            Apply
          </Button>
        </Grid>
      </Grid>

      {invalidInput && (
        <Grid item>
          <Typography sx={{ color: "red" }}>
            Invalid input. Please upload a valid format as specified in template
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default KeyValuePairsGrid;
