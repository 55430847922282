import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
  LinearProgress,
  IconButton,
  Alert,
} from "@mui/material";
import CustomCalendar from "../../../components/misc/custom-calendar";
import ActivityService from "../../../services/api/activity-service";
import StorageService from "../../../services/local-storage/storage-service";
import CheckboxForm from "../../../components/form-elements/checkbox";
import LargeLayout from "../../../components/layouts/large-layout";
import ScenariosContainer from "../../scenario/scenarios-container";
import DynamicCheckboxForm from "../../../components/form-elements/dynamic-checkbox";
import CircularProgress from "@mui/material/CircularProgress";

import Papa from "papaparse";
import useTracker from "../../../hooks/use-tracker";
import useDocTitle from "../../../hooks/use-doc-title";
import ScenarioService from "../../../services/api/scenario-service";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import UserList from "../../../components/list/user-list";
import ChallengeDetailsForm from "./challenge-details-form";
import KeyValuePairsGrid from "./key-value-pairs-grid";
import DynamicValuesGrid from "./dynamic-values-grid";

function FileBrowser(props) {
  const inputRef = React.createRef();

  const onClick = () => {
    inputRef.current.click();
  };

  const onChange = (setFile) => (event) => {
    // reset the file
    Papa.parse(event.target.files[0], {
      complete: function (results) {
        let temp = [];
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email validation

        for (const arr of results.data) {
          let el = null;
          if (arr.length > 1) {
            // which means comma separated values
            // depending on the file name, set element types
            // at time of writing, only scenarios is in CSV type
            if (arr[0].trim().length === 0 || arr[1].trim().length === 0) {
              continue;
            }
            el = {
              scenario_id: arr[0].trim(),
              version: arr[1].trim(),
            };
          } else {
            if (arr[0].trim().length === 0) {
              continue;
            }
            //check if the mails are invalid if the title is "Admins File" or "Users File"
            if (props.checkType === "email" && !emailRegex.test(arr[0])) {
              continue;
            }

            el = arr[0];
          }
          if (!temp.includes(el)) {
            temp.push(el);
          }
        }
        setFile(temp);
      },
    });
  };

  return (
    <React.Fragment>
      <input
        type="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={onChange(props.setFile)}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={onClick}
        sx={{
          width: "fit-content",
        }}
      >
        {props.text}
      </Button>
    </React.Fragment>
  );
}

export default function ChallengeCreate(props) {
  const [pageTitle, setPageTitle] = useDocTitle("Create Challenge");
  useTracker();
  const user = StorageService.getUserInformation();
  const modelLabels = ["PM", "KS", "ST", "MB"];
  const typeLabels = ["1", "2", "3"];
  const costFunctionLabels = [
    "JB1",
    "SA1",
    "WX1",
    "SM1",
    "SM2",
    "SM3",
    "MW1",
    "TR1",
  ];
  const labels = [
    {
      label: "It is a public challenge that is visible to everyone.",
      name: "public",
    },
    {
      label:
        "It's a private challenge and I am going to supply the emails of the users who are allowed to enroll. " +
        "Users need to create an account with this email and need to be logged in to see the challenge.",
      name: "private",
    },
  ];
  const permissionLabels = user.is_activity_creator
    ? labels
    : labels.filter((x) => x.name === "private");

  const editMode = "edit" in props;
  const activityId = props.id;
  const ParentComponent = editMode ? React.Fragment : LargeLayout;

  const [selected, setSelected] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [summary, setSummary] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [model, setModel] = React.useState("");
  const [type, setType] = React.useState("");
  const [costFunction, setCostFunction] = React.useState("");
  const [permission, setPermission] = React.useState(
    user.is_activity_creator ? "" : "private"
  );
  const [scenariosFile, setScenariosFile] = React.useState([]); // scenario-name, version
  const [adminsFile, setAdminsFile] = React.useState([]);
  const [usersFile, setUsersFile] = React.useState([]);
  const [hidden, setHidden] = React.useState(false);

  const [submitErrors, setSubmitErrors] = React.useState([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [unknownScenarios, setUnknownScenarios] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  const [hasChallengeDataLoding, setHasChallengeDataLoding] =
    React.useState(false);

  const [stage, setStage] = useState(1);

  const [scenarioPairs, setScenarioPairs] = React.useState([
    { scenario_id: "", version: "" },
  ]);

  const [adminsValues, setAdminsValues] = React.useState([""]);

  const [usersValues, SetUsersValues] = React.useState([""]);


  useEffect(() => {
    if (submitErrors.length > 0) {
      window.scrollTo(0, 0);
    }
  }, [submitErrors]);

  useEffect(() => {
    if (editMode) {
      ActivityService.getActivity(activityId).then((response) => {
        setTitle(response.data.title);
        setSummary(response.data.summary);
        setDescription(response.data.description);
        setStartDate(new Date(response.data.start_date));
        setEndDate(new Date(response.data.end_date));
        setModel(response.data.vehicle_models[0]);
        setType(response.data.vehicle_types[0]);
        setCostFunction(response.data.cost_functions[0]);
        setSelected(response.data.scenarios);
        setHidden(response.data.hidden);
        setPermission(response.data.public ? "public" : "private");
        setUsers(response.data.users);
        setAdminsFile(response.data.users.filter((user) => user.is_admin).map((user) => user.email));
        setUsersFile(response.data.users.filter((user) => !user.is_admin).map((user) => user.email));
        setScenarioPairs( response.data.scenarios.map((scenario) => ({ scenario_id: scenario.scenario_id, version: scenario.version }) ));
        setHasChallengeDataLoding(true);
      });
      setPageTitle("Edit Challenge");
    }
  }, [activityId, editMode]);

  // set selected scenarios in the table if scenarios file has changed
  React.useEffect(() => {
    ScenarioService.getAllScenarioIds()
      .then((response) => {
        const scenarioIds = response.data.scenario_ids;

        let unknownScenarios = [];
        let knownScenarios = [];
        for (const scenarioInFile of scenariosFile) {
          if (
            !scenarioIds.some(
              (scenarioId) =>
                scenarioId["scenario_id"] === scenarioInFile["scenario_id"] &&
                scenarioId["version"] === scenarioInFile["version"]
            )
          ) {
            unknownScenarios.push(scenarioInFile);
          } else {
            knownScenarios.push(scenarioInFile);
          }
        }
        setUnknownScenarios(unknownScenarios);
        setSelected(knownScenarios.concat(unknownScenarios));
      })
      .catch((error) => {
        return error;
      });
  }, [scenariosFile]);

  const handleCheckChangeModel = (event) => {
    if (event.target.checked) {
      setModel(event.target.name);
    } else {
      setModel("");
    }
  };

  const handleCheckChangeType = (event) => {
    if (event.target.checked) {
      setType(event.target.name);
    } else {
      setType("");
    }
  };

  const handleCheckChangePermission = (event) => {
    if (event.target.checked) {
      setPermission(event.target.name);
    } else {
      setPermission("");
    }
  };

  const handleCheckHidden = (event) => {
    setHidden(event.target.checked);
  };

  const handleCheckCostFunctionChange = (event) => {
    if (event.target.checked) {
      setCostFunction(event.target.name);
    } else {
      setCostFunction("");
    }
  };

  const handleSubmitClicked = () => {
    // set mode to submit
    setSubmitting(true);

    // check if usersFile and adminsFile are all valid emails
    let valid = true;
    if (usersFile.length > 0) {
      const newErrors = usersFile.map((value) =>
        !value.includes("@") ? true : false
      );

      setSubmitErrors(newErrors);

      if (newErrors.includes(true)) {
        valid = false;
      }
    }

    if (adminsFile.length > 0) {
      const newErrors = adminsFile.map((value) =>
        !value.includes("@") ? true : false
      );

      setSubmitErrors(newErrors);

      if (newErrors.includes(true)) {
        valid = false;
      }
    }

    if (!valid) {
      setSubmitting(false);
      return;
    }

    const current_admins_email = users
      .filter((user) => user.is_admin)
      .map((user) => user.email);

    const additional_admins = adminsFile
      .filter((email) => !current_admins_email.includes(email))
      .map((email) => ({ email: email, is_admin: true }));

    const additional_users = usersFile
      .filter((email) => !users.map((user) => user.email).includes(email))
      .filter((email) => !adminsFile.includes(email))
      .map((email) => ({ email: email, is_admin: false }));

    const data = {
      title: title,
      summary: summary,
      description: description,
      scenarios: selected,
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
      vehicle_models: [model],
      vehicle_types: [type],
      cost_functions: [costFunction],
      users: users.concat(additional_admins).concat(additional_users),
      hidden: hidden,
      public: permission === "public",
    };

    if (editMode) {
      ActivityService.update(activityId, data)
        .then((response) => {
          // update local storage information as well
          for (let i = 0; i < user.admin_activities.length; i++) {
            if (user.admin_activities[i].id === activityId) {
              user.admin_activities[i].title = title;
            }
          }
          StorageService.storeUserInformation(user);
          props.setEdit(false);
        })
        .catch((error) => {
          const errors = Object.entries(error.data).map((error) => {
            // reconstruct field name nicely so that we can use it while showing the regarding error message
            const field = error[0]
              .replace("_", " ")
              .split(" ")
              .map((e) => e.charAt(0).toUpperCase() + e.slice(1))
              .join(" ");

            // Oth index holds the field name
            // 1st index holds the specific error message for the field
            if (Array.isArray(error[1])) {
              return `${field}: ${error[1][0]}`;
            } else if (typeof error[1] === "object") {
              return `${field}: ${error[1][0][0]}`;
            } else {
              return `${field}: ${error[1]}`;
            }
          });
          setSubmitErrors(errors);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      ActivityService.create(data)
        .then((response) => {
          user.activities.push(response.data.id);
          user.admin_activities.push({
            id: response.data.id,
            title: response.data.title,
          });
          StorageService.storeUserInformation(user);
          window.location = "/challenges/admin"; //TODO: change the window location to another URI
        })
        .catch((error) => {
          const errors = Object.entries(error.data).map((error) => {
            // reconstruct field name nicely so that we can use it while showing the regarding error message
            const field = error[0]
              .replace("_", " ")
              .split(" ")
              .map((e) => e.charAt(0).toUpperCase() + e.slice(1))
              .join(" ");

            // Oth index holds the field name
            // 1st index holds the specific error message for the field
            if (Array.isArray(error[1])) {
              return `${field}: ${error[1][0]}`;
            } else if (typeof error[1] === "object") {
              return `${field}: ${error[1][0][0]}`;
            } else {
              return `${field}: ${error[1]}`;
            }
          });
          setSubmitErrors(errors);
          console.log(error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const handleNextStage = () => {
    setStage(2);
  };

  const handleBack = () => {
    setStage(1);
  };

  const renderChallengeComponents = () => {
    return (
      <ParentComponent>
        <Grid item xs={12}>
          <Typography variant="h3">
            {editMode ? "Edit Challenge" : "Create Challenge"}
          </Typography>
        </Grid>

        {submitErrors.length > 0 && (
          <Grid item xs={12}>
            <Alert severity="error">
              Please check missing fields and submit your changes again
            </Alert>
          </Grid>
        )}
        <Grid container spacing={3}>
          {stage === 1 && (
            <ChallengeDetailsForm
              title={title}
              setTitle={setTitle}
              summary={summary}
              setSummary={setSummary}
              description={description}
              setDescription={setDescription}
              onNextStage={handleNextStage}
            />
          )}
          {stage === 2 && (
            <Grid
              container
              alignItems="flex-start"
              spacing={2}
              style={{
                padding: "40px",
              }}
            >
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item>
                  <Typography
                    variant="h6"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    Select start and end dates:
                  </Typography>
                </Grid>
                <Grid item container spacing={2} justifyContent="space-evenly">
                  <Grid item>
                    <Typography variant="body1">
                      Start Date (*required)
                    </Typography>
                    <CustomCalendar
                      label="Start Date"
                      value={startDate}
                      onChange={setStartDate}
                    />
                  </Grid>

                  <Grid item>
                    <Typography variant="body1">End Date</Typography>
                    <CustomCalendar
                      label="End Date"
                      value={endDate}
                      onChange={setEndDate}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {user.is_activity_creator && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Divider
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </Grid>
                  <Grid
                    container
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    spacing={2}
                  >
                    <Grid item>
                      <Typography variant="h6">
                        Should participants submit their planners as docker
                        images, which are evaluated using unknown scenarios?
                        Otherwise, they must submit XML solution files for known
                        scenarios.
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={hidden}
                            onChange={handleCheckHidden}
                          />
                        }
                        label="Evaluate using unknown scenarios."
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
              <Grid item xs={12}>
                <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
              </Grid>

              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h6">
                    Select which scenarios to include:
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  direction="column"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Typography variant="body2">
                      Apply filters to narrow down the search and select the
                      scenarios in the table below.
                      <br />
                      If you have already got a txt file of scenarios to add to
                      the challenge, you can submit it via the below button.
                      (For how to generate such a file, you must follow the
                      rules in this{" "}
                      {
                        <a
                          href={
                            require("../../../assets/admin-templates/scenarios.txt")
                              .default
                          }
                          download="scenarios.txt"
                        >
                          template
                        </a>
                      }{" "}
                      file.)
                    </Typography>
                  </Grid>

                  <Grid item container direction="column" spacing={2}>
                    <Grid item>
                      <FileBrowser
                        text="Upload Scenarios File"
                        setFile={setScenariosFile}
                      />
                    </Grid>

                    <Grid item>
                      <KeyValuePairsGrid
                        title="Scenario"
                        pairs={scenarioPairs}
                        setPairs={setScenarioPairs}
                        field1Title={"Scenario"}
                        field2Title={"Version"}
                        keyTitle={"scenario_id"}
                        valueTitle={"version"}
                        file={scenariosFile}
                        setFile={setScenariosFile}
                      />
                    </Grid>
                  </Grid>
                  {scenariosFile.length > 0 && (
                    <Grid
                      item
                      container
                      xs={12}
                      spacing={1}
                      style={{ marginBottom: "1vh", marginTop: "1vh" }}
                    >
                      <Grid item xs={12}>
                        <Paper elevation={1}>
                          <Alert severity="success">
                            Scenarios successfully applied. You can see the
                            results from the table below.{" "}
                          </Alert>
                        </Paper>
                      </Grid>
                    </Grid>
                  )}

                  {unknownScenarios.length > 0 && (
                    <Grid item container spacing={1}>
                      <Grid item>
                        <Paper elevation={1}>
                          <Alert severity="warning">
                            Some warnings were thrown while looking up
                            scenarios. Expand to see the details.
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              style={{ marginLeft: "5px" }}
                              onClick={() => setCollapseOpen((open) => !open)}
                            >
                              {collapseOpen ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </Alert>
                        </Paper>
                      </Grid>
                      <Grid item>
                        <Collapse in={collapseOpen} timeout="auto">
                          <Paper elevation={1}>
                            {unknownScenarios.map((s) => {
                              return (
                                <Alert
                                  key={s.scenario_id}
                                  style={{ margin: "5px" }}
                                  severity="warning"
                                >{`${s.scenario_id} with version ${s.version} is not present in the database. Please revise your file again.`}</Alert>
                              );
                            })}
                          </Paper>
                        </Collapse>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <ScenariosContainer
                    selectable={true}
                    selected={selected}
                    downloadable={false}
                    public={!hidden}
                    showPublicFilter={false}
                    setSelected={setSelected}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
              </Grid>

              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={2}
              >
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Select vehicle model for the challenge (see{" "}
                    <a href={"/model-cost-functions"}>documentation</a> for more
                    information):
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <DynamicCheckboxForm
                    row
                    r
                    currentLabel="model"
                    labels={modelLabels}
                    selected={model}
                    onChange={handleCheckChangeModel}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
              </Grid>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h6">
                    Select vehicle type for the challenge (see{" "}
                    <a href={"/model-cost-functions"}>documentation</a> for more
                    information):
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <DynamicCheckboxForm
                    row
                    required
                    currentLabel="type"
                    labels={typeLabels}
                    selected={type}
                    onChange={handleCheckChangeType}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
              </Grid>

              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h6">
                    Select the cost function (see{" "}
                    <a href={"/model-cost-functions"}>documentation</a> for more
                    information):
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <DynamicCheckboxForm
                    row
                    required
                    currentLabel="costFunction"
                    labels={costFunctionLabels}
                    selected={costFunction}
                    onChange={handleCheckCostFunctionChange}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
              </Grid>

              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={2}
              >
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Submit the emails of the admin users, who should be able to
                    edit this challenge
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  You can add several admins to your challenge by submitting a
                  .txt file of mails of registered users. You must follow the
                  rules in the{" "}
                  {
                    <a
                      href={
                        require("../../../assets/admin-templates/admins.txt")
                          .default
                      }
                      download="admins.txt"
                    >
                      template
                    </a>
                  }{" "}
                  file.
                </Grid>

                <Grid item container direction="column" spacing={2}>
                  <Grid item>
                    <FileBrowser
                      checkType="email"
                      text="Admins File"
                      setFile={setAdminsFile}
                    />
                  </Grid>

                  <Grid item>
                    <DynamicValuesGrid
                      title="Admin"
                      values={adminsValues}
                      setValues={setAdminsValues}
                      fieldTitle="Email"
                      checkValidInput="email"
                      file={adminsFile}
                      setFile={setAdminsFile}
                    />
                  </Grid>
                </Grid>

                {adminsFile.length > 0 && (
                  <Grid item xs={12}>
                    <UserList
                      users={adminsFile.map((email) => ({ username: email }))}
                      Icon={PersonAddIcon}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
              </Grid>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h6">
                    Select whom is permitted to enter the challenge
                    {!user.is_activity_creator
                      ? ". (Contact us if you want to host public challenges)"
                      : null}
                  </Typography>
                </Grid>

                {user.is_activity_creator ? (
                  <Grid item xs={12}>
                    <CheckboxForm
                      labels={permissionLabels}
                      selected={permission}
                      onChange={handleCheckChangePermission}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    You can add several participants to your challenge by
                    submitting a .txt file of mails of registered users. You
                    must follow the rules in the{" "}
                    {
                      <a
                        href={
                          require("../../../assets/admin-templates/admins.txt")
                            .default
                        }
                        download="users.txt"
                      >
                        template
                      </a>
                    }{" "}
                    file.
                  </Grid>
                )}

                {permission === "private" && (
                  <Grid item container direction="column" spacing={2}>
                    <Grid item>
                      <FileBrowser
                        checkType="email"
                        text="Users File"
                        setFile={setUsersFile}
                      />
                    </Grid>

                    <Grid item>
                      <DynamicValuesGrid
                        title="User"
                        values={usersValues}
                        setValues={SetUsersValues}
                        fieldTitle="Email"
                        checkValidInput="email"
                        file={usersFile}
                        setFile={setUsersFile}
                      />
                    </Grid>
                  </Grid>
                )}
                {permission === "private" && usersFile.length > 0 && (
                  <Grid item xs={12}>
                    <UserList
                      users={usersFile.map((email) => ({ username: email }))}
                      Icon={PersonAddIcon}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
              </Grid>

              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={submitting}
                    onClick={handleBack}
                    style={{ marginTop: "20px", marginRight: "20px" }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={submitting}
                    onClick={handleSubmitClicked}
                    style={{ marginTop: "20px" }}
                  >
                    Save
                  </Button>
                  {submitting && (
                    <CircularProgress
                      color="primary"
                      size={24}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: -12,
                        marginLeft: -12,
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </ParentComponent>
    );
  };

  const renderMethod = () => {
    if (editMode) {
      return (
        <>
          {hasChallengeDataLoding ? (
            renderChallengeComponents()
          ) : (
            <LinearProgress />
          )}
        </>
      );
    } else {
      return <>{renderChallengeComponents()}</>;
    }
  };

  return <>{renderMethod()}</>;
}
