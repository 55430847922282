import React from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const MuiTableRenderer = React.memo(({ children }) => (
  <TableContainer component={Paper}>
    <Table>{children}</Table>
  </TableContainer>
));

const MuiTableRowRenderer = React.memo(({ children }) => (
  <TableRow>{children}</TableRow>
));

const headerStyle = { fontWeight: 'bold' };
const cellStyle = { fontWeight: 'normal' };

const MuiTableCellRenderer = React.memo(({ children, isHeader }) => (
  <TableCell 
    component={isHeader ? "th" : "td"} 
    style={isHeader ? headerStyle : cellStyle}
  >
    {children}
  </TableCell>
));

MuiTableRenderer.displayName = 'MuiTableRenderer';
MuiTableRowRenderer.displayName = 'MuiTableRowRenderer';
MuiTableCellRenderer.displayName = 'MuiTableCellRenderer';

export { MuiTableRenderer, MuiTableRowRenderer, MuiTableCellRenderer };