import React from "react";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import ReactMarkdown from "react-markdown";
import { useState, useEffect, useCallback, useMemo } from "react";
import { Typography } from "@mui/material";
import externalProjects from "../../assets/markdowns/external-projects/external-projects-table.md";
import { LinkRenderer } from "../../components/markdown/link-renderer";
import { MuiTableRenderer, MuiTableRowRenderer, MuiTableCellRenderer } from "../../components/markdown/table-renderer";

export default function ExternalProjectsPage() {
  useDocTitle("External Projects");
  useTracker();

  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    let isMounted = true;
    fetch(externalProjects)
      .then((response) => response.text())
      .then((text) => {
        if (isMounted) {
          setMarkdown(text);
        }
      })
      .catch((error) => console.error("Error fetching markdown:", error));

    return () => {
      isMounted = false;
    };
  }, []);

  const renderers = useMemo(() => ({
    link: LinkRenderer,
    table: MuiTableRenderer,
    tableRow: MuiTableRowRenderer,
    tableCell: MuiTableCellRenderer,
  }), []);

  const MarkdownComponent = useCallback(() => (
    <ReactMarkdown renderers={renderers}>{markdown}</ReactMarkdown>
  ), [markdown, renderers]);

  return (
    <DefaultLayout>
      <Typography variant="h4" className="pb-4">External Projects</Typography>
      <MarkdownComponent />
    </DefaultLayout>
  );
}