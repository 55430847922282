import "./css/app.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import React, { Component } from "react";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import tools from "./json/tools.json";

import Index from "./pages/index";

import SignIn from "./pages/user/signin";
import SignUp from "./pages/user/signup";
import ReActivate from "./pages/user/re-activate";
import ProfilePage from "./pages/user/profile";
import ResetPassword from "./pages/user/reset";
import AccountRecovery from "./pages/user/recover";
import EmailVerification from "./pages/user/verify";

// import SSO from './pages/user/sso';

import Scenarios from "./pages/scenario/scenarios";
import Teaching from "./pages/static/teaching";
import Internship from "./pages/static/internship";

import TeamPage from "./pages/static/team-page";
import ExternalProjectsPage from "./pages/static/external-projects";

import PrivacyPolicy from "./pages/static/privacy-policy";
import GettingStarted from "./pages/static/getting-started";
import Publications from "./pages/static/publications";
import ScenarioBenchmarks from "./pages/benchmark/scenario-benchmarks";
import SolutionsGlobalRanking from "./pages/ranking/global-ranking/solutions-global-ranking";
import BenchmarkRankings from "./pages/ranking/benchmark-rankings/solutions-benchmark-rankings";
import NewSubmission from "./pages/submission/new-submission";
import SubmissionDetails from "./pages/submission/submission-details";
import Challenges from "./pages/challenge/challenges";
import Challenge from "./pages/challenge/challenge";
import Submissions from "./pages/user/submission/submissions-main";
import Tutorial from "./pages/tutorials/tutorial";
import Tool from "./pages/static/tool";
import Develop from "./pages/develop";
import ForumSSO from "./pages/forum/forum-sso";
import CompetitionAnnouncement2021 from "./pages/static/competition/2021/competition-announcement";
import CompetitionDetails2021 from "./pages/static/competition/2021/competition-details";
import CompetitionOrganizers2021 from "./pages/static/competition/2021/competition-organizers";
import CompetitionAnnouncement2022 from "./pages/static/competition/2022/competition-announcement";
import CompetitionDetails2022 from "./pages/static/competition/2022/competition-details";
import CompetitionOrganizers2022 from "./pages/static/competition/2022/competition-organizers";
import CompetitionAnnouncement2023 from "./pages/static/competition/2023/competition-announcement";
import CompetitionOrganizers2023 from "./pages/static/competition/2023/competition-organizers";
import CompetitionDetails2023 from "./pages/static/competition/2023/competition-details";
import CompetitionAnnouncement2024 from "./pages/static/competition/2024/competition-announcement";
import CompetitionOrganizers2024 from "./pages/static/competition/2024/competition-organizers";
import CompetitionDetails2024 from "./pages/static/competition/2024/competition-details";
import StorageService from "./services/local-storage/storage-service";
import ChallengeCreate from "./pages/admin/challenge/challenge-create";
import ChallengeView from "./pages/admin/challenge/challenge-view";
import NotFound from "./pages/static/errors/not-found-error";
import APIError from "./pages/static/errors/api-error";
import ChallengeCreateTutorial from "./pages/static/challenge-create";
import MaintenancePage from "./pages/static/maintenance-page";
import Datasets from "./pages/dataset/datasets";

import ErrorHandler from "./error-checkers/error-context";
import ErrorBoundary from "./error-checkers/error-boundary";
import Imprint from "./pages/static/imprint";

const theme = createTheme({
  div: {
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
  },

  typography: {
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      light: "#4992e0",
      main: "#0a5fb8",
      dark: "#043c82",
      contrastText: "#fff",
    },
    secondary: {
      light: "#e08131",
      main: "#aa0a22",
      dark: "#740211",
      contrastText: "#fff",
    },
  },
});

function PrivateRoute({ component: Component, ...rest }) {
  const userId = StorageService.getUserId();
  return (
    <Route
      {...rest}
      render={(props) =>
        userId ? <Component {...props} /> : <Redirect to="/signin" />
      }
    />
  );
}

function RouteWithErrorBoundary(props) {
  return (
    <ErrorBoundary key={props.location?.key}>
      {props.private ? <PrivateRoute {...props} /> : <Route {...props} />}
    </ErrorBoundary>
  );
}

class App extends Component {
  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            <ErrorHandler>
              <Switch>
                {/* index page */}
                <RouteWithErrorBoundary exact path="/" component={Index} />
                {/* User routes */}
                <RouteWithErrorBoundary
                  exact
                  path="/signin"
                  component={SignIn}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/signup"
                  component={SignUp}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/reactivate"
                  component={ReActivate}
                />
                <RouteWithErrorBoundary
                  private
                  exact
                  path="/profile"
                  component={ProfilePage}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/recover/:token"
                  component={ResetPassword}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/recover/"
                  component={AccountRecovery}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/verify/:token"
                  component={EmailVerification}
                />

                <RouteWithErrorBoundary
                  exact
                  path="/scenarios"
                  component={Scenarios}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/scenarios/:scenarioId/benchmarks"
                  component={ScenarioBenchmarks}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/solutions/ranking/"
                  component={SolutionsGlobalRanking}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/solutions/ranking/:benchmarkId"
                  component={BenchmarkRankings}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/solutions/:id/details"
                  component={SubmissionDetails}
                />
                <RouteWithErrorBoundary
                  private
                  exact
                  path="/new-submission"
                  component={NewSubmission}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/challenges"
                  component={Challenges}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/challenges/id/:challengeId"
                  component={Challenge}
                />
                <RouteWithErrorBoundary
                  private
                  exact
                  path="/user/submissions"
                  component={Submissions}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/tutorials/:tutorial"
                  component={Tutorial}
                />

                {/** Challenge Admin Dashboard */}
                <RouteWithErrorBoundary
                  exact
                  path="/challenges/admin"
                  component={ChallengeView}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/challenges/admin/create"
                  component={ChallengeCreate}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/challenges/tutorial"
                  component={ChallengeCreateTutorial}
                />

                {/** Forum redirect */}
                <RouteWithErrorBoundary
                  exact
                  path="/sso/"
                  component={ForumSSO}
                />

                {/** Team */}
                <RouteWithErrorBoundary
                  exact
                  path="/team"
                  component={TeamPage}
                />

                {/** External Projects */}
                <RouteWithErrorBoundary
                  exact
                  path="/external-projects"
                  component={ExternalProjectsPage}
                />

                {/** Dataset */}
                <RouteWithErrorBoundary
                  exact
                  path="/datasets"
                  component={Datasets}
                />

                {/** Competition 2021 */}
                <RouteWithErrorBoundary
                  exact
                  path="/competition/2021/announcement"
                  component={CompetitionAnnouncement2021}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/competition/2021/details"
                  component={CompetitionDetails2021}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/competition/2021/organizers"
                  component={CompetitionOrganizers2021}
                />

                {/** Competition 2022 */}
                <RouteWithErrorBoundary
                  exact
                  path="/competition/2022/announcement"
                  component={CompetitionAnnouncement2022}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/competition/2022/details"
                  component={CompetitionDetails2022}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/competition/2022/organizers"
                  component={CompetitionOrganizers2022}
                />

                {/** Competition 2023 */}
                <RouteWithErrorBoundary
                  exact
                  path="/competition/2023/announcement"
                  component={CompetitionAnnouncement2023}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/competition/2023/details"
                  component={CompetitionDetails2023}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/competition/2023/organizers"
                  component={CompetitionOrganizers2023}
                />

                {/** Competition 2024 */}
                <RouteWithErrorBoundary
                  exact
                  path="/competition/2024/announcement"
                  component={CompetitionAnnouncement2024}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/competition/2024/details"
                  component={CompetitionDetails2024}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/competition/2024/organizers"
                  component={CompetitionOrganizers2024}
                />
                {/* redirect from old URL */}
                <RouteWithErrorBoundary
                  exact
                  path="/competition/announcement"
                  component={CompetitionAnnouncement2024}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/competition/organizers"
                  component={CompetitionOrganizers2024}
                />

                {/* Documentations */}
                <RouteWithErrorBoundary
                  exact
                  path="/getting-started"
                  component={GettingStarted}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/teaching"
                  component={Teaching}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/internship"
                  component={Internship}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/publications"
                  component={Publications}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/develop"
                  component={Develop}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/privacy-policy"
                  component={PrivacyPolicy}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/impressum"
                  component={Imprint}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/tools/:tool"
                  component={Tool}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/competition-call"
                  component={CompetitionAnnouncement2023}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/api-error"
                  component={APIError}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/maintenance"
                  component={MaintenancePage}
                />

                {/*  DEPRECATED ROUTES */}
                <RouteWithErrorBoundary
                  exact
                  path="/submissions/ranking/"
                  component={SolutionsGlobalRanking}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/submissions/ranking/:benchmarkId"
                  component={BenchmarkRankings}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/submissions/:id/details"
                  component={SubmissionDetails}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/competition-announcement"
                  component={CompetitionAnnouncement2023}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/competition-call"
                  component={CompetitionAnnouncement2023}
                />
                <Route
                  exact
                  path="/drivability_checker"
                  render={(props) => (
                    <Tool input={tools["drivability_checker"]} />
                  )}
                />

                {/** if nothing matches, show 404 page */}
                <RouteWithErrorBoundary component={NotFound} />

                {/*  DEPRECATED ROUTES */}
                <RouteWithErrorBoundary
                  exact
                  path="/submissions/ranking/"
                  component={SolutionsGlobalRanking}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/submissions/ranking/:benchmarkId"
                  component={BenchmarkRankings}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/submissions/:id/details"
                  component={SubmissionDetails}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/competition-announcement"
                  component={CompetitionAnnouncement2023}
                />
                <RouteWithErrorBoundary
                  exact
                  path="/competition-call"
                  component={CompetitionAnnouncement2023}
                />
              </Switch>
            </ErrorHandler>
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default App;
