import React from 'react';
import { GitHub, Article } from '@mui/icons-material';

const iconStyle = { marginRight: '5px' };
const linkStyle = { display: 'flex', alignItems: 'center' };

const GithubIcon = React.memo(() => <GitHub style={iconStyle} />);
const ArticleIcon = React.memo(() => <Article style={iconStyle} />);

const LinkRenderer = React.memo(({ href, children }) => {
  let icon = null;
  
  const linkText = React.useMemo(() => {
    if (typeof children === 'string') return children;
    if (Array.isArray(children)) {
      return children.map(child => 
        typeof child === 'string' ? child : child.props?.children
      ).join('');
    }
    return '';
  }, [children]);

  if (linkText.includes('Github')) {
    icon = <GithubIcon />;
  } else if (linkText.includes('Article')) {
    icon = <ArticleIcon />;
  }

  return (
    <a href={href} target="_blank" rel="noopener noreferrer" style={linkStyle}>
      {icon}
    </a>
  );
});

LinkRenderer.displayName = 'LinkRenderer';

export { LinkRenderer };