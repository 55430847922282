import React from "react";
import ReactMarkdown from "react-markdown";

import competitionAnnouncement from "../../../../assets/markdowns/competition/2022/competition-announcement.md";
import { Paper, Typography } from "@mui/material";
import DefaultLayout from "../../../../components/layouts/default-layout";
import useDocTitle from "../../../../hooks/use-doc-title";
import useTracker from "../../../../hooks/use-tracker";
import Grid from "@mui/material/Grid";
import { QuickAccessCompetition2022 } from "../../../../components/quick-access-competition";
import baseStyle from "../../../../css/const";

export default function CompetitionAnnouncement2022() {
  useDocTitle("Competition 2022");
  useTracker();

  const [markdown, setMarkdown] = React.useState("");
  const classes = baseStyle();

  React.useEffect(() => {
    fetch(competitionAnnouncement)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  return (
    <DefaultLayout>
      <Grid container direction={"row"} spacing={2}>
        <Grid item xs={12} md={10}>
          <Paper className={classes.paper}>
            <ReactMarkdown escapeHtml={false} children={markdown} />
            <h3>Prize Sponsor</h3>
            <br />
            <img
              style={{ width: "200px", marginBottom: "1rem" }}
              src={
                require("../../../../assets/images/competition-announcement/2021/IVEX_logo.png")
                  .default
              }
              alt="tutorial preview"
            />
            <h3>Acknowledgement</h3>
            <br />
            <img
              style={{ width: "200px" }}
              src={
                require("../../../../assets/images/competition-announcement/2021/dfg_logo_englisch_blau_en.jpg")
                  .default
              }
              alt="tutorial preview"
            />
            <Typography variant="body2">
              {" "}
              For a PDF version of this call, see here: &nbsp;
              <a
                href={
                  require("../../../../assets/pdf/competition-announcement/2022/callForSubmissions_22.pdf")
                    .default
                }
                target="_blank"
              >
                download.{" "}
              </a>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={2}>
          <QuickAccessCompetition2022 />
        </Grid>
      </Grid>
    </DefaultLayout>
  );
}
