import React from "react";
import ReactMarkdown from "react-markdown";

import competitionOrganizers from "../../../../assets/markdowns/competition/2021/competition-organizers.md";
import { Paper } from "@mui/material";
import DefaultLayout from "../../../../components/layouts/default-layout";
import useDocTitle from "../../../../hooks/use-doc-title";
import useTracker from "../../../../hooks/use-tracker";
import Grid from "@mui/material/Grid";
import { QuickAccessCompetition2021 } from "../../../../components/quick-access-competition";
import baseStyle from "../../../../css/const";

export default function CompetitionOrganizers2021() {
  useDocTitle("Competition 2021 - Organizers");
  useTracker();

  const [markdown, setMarkdown] = React.useState("");
  const classes = baseStyle();

  React.useEffect(() => {
    fetch(competitionOrganizers)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  return (
    <DefaultLayout>
      <Grid container direction={"row"} spacing={2}>
        <Grid item xs={12} md={10}>
          <Paper className={classes.paper}>
            <ReactMarkdown children={markdown}></ReactMarkdown>
          </Paper>
        </Grid>
        <Grid item xs={12} md={2}>
          <QuickAccessCompetition2021 />
        </Grid>
      </Grid>
    </DefaultLayout>
  );
}
