const navbarLinks = [
  {
    text: "Getting Started",
    type: "button",
    linkType: "inner",
    to: "/getting-started",
  },

  {
    text: "Benchmarks",
    type: "menu",
    menuItems: [
      {
        text: "Scenario Selection Tool",
        linkType: "inner",
        to: "/scenarios",
      },

      {
        text: "Benchmark Leaderboards",
        linkType: "inner",
        to: "/solutions/ranking",
      },

      {
        text: "Challenges",
        linkType: "inner",
        to: "/challenges",
      },

      {
        text: "Datasets",
        linkType: "inner",
        to: "/datasets",
      },

      {
        text: "Scenario Repository",
        linkType: "outer",
        to: "https://gitlab.lrz.de/tum-cps/commonroad-scenarios",
      },

      {
        text: "Scenario Format Documentation 2020a (.pdf)",
        linkType: "outer",
        to: "https://gitlab.lrz.de/tum-cps/commonroad-scenarios/-/blob/master/documentation/XML_commonRoad_2020a.pdf",
      },

      {
        text: "XML Schema Definition 2020a (.xsd)",
        linkType: "outer",
        to: "https://gitlab.lrz.de/tum-cps/commonroad-scenarios/-/blob/master/documentation/XML_commonRoad_XSD_2020a.xsd",
      },

      {
        text: "Traffic Rule Format Documentation (.pdf)",
        linkType: "outer",
        to: "https://gitlab.lrz.de/tum-cps/traffic-rules/-/blob/master/traffic_rules.pdf",
      },
    ],
  },

  {
    text: "Community",
    type: "menu",
    menuItems: [
      {
        text: "Forum",
        linkType: "outer",
        to: process.env.REACT_APP_FORUM_URL,
      },

      {
        text: "Publications",
        linkType: "inner",
        to: "/publications",
      },
      {
        text: "Teaching Material",
        linkType: "inner",
        to: "/teaching",
      },
      {
        text: "Tutorial: Host a Challenge",
        linkType: "inner",
        to: "/challenges/tutorial",
      },
      {
        text: "Internship Opportunities",
        linkType: "inner",
        to: "/internship",
      },
      {
        text: "Team",
        linkType: "inner",
        to: "/team",
      },
      {
        text: "External Projects",
        linkType: "inner",
        to: "/external-projects",
      }
    ],
  },

  {
    text: "Competition 2024",
    type: "menu",
    menuItems: [
      {
        text: "Announcement: Commonroad Motion Planning Competition for Autonomous Vehicles",
        type: "button",
        linkType: "inner",
        to: "/competition/2024/announcement",
      },
      {
        text: "Rules and Details",
        type: "button",
        linkType: "inner",
        to: "/competition/2024/details",
      },
      // Will be activated later
      {
        text: "Organizers",
        type: "button",
        linkType: "inner",
        to: "/competition/2024/organizers",
      },
      // activated
      {
        text: "Leaderboard Phase 1",
        type: "button",
        linkType: "inner",
        to: "/challenges/id/4345b16a-16b2-46e9-aa3e-6f20f73e5dae",
      },
      {
        text: "Leaderboard Phase 2",
        type: "button",
        linkType: "inner",
        to: "/challenges/id/f6db9c31-4d79-4751-8019-203b6c8be1b3",
      },
      // Will be activated later
      // {
      //   text: "Newsletter",
      //   type: "button",
      //   linkType: "outer",
      //   to: "https://forms.gle/KtdxY9iXypz6t1zM9",
      // },
      {
        text: "Archive",
        type: "menu",
        menuItems: [
          {
            text: "Competition 2021",
            type: "button",
            linkType: "inner",
            to: "/competition/2021/announcement",
          },
          {
            text: "Competition 2022",
            type: "button",
            linkType: "inner",
            to: "/competition/2022/announcement",
          },
          {
            text: "Competition 2023",
            type: "button",
            linkType: "inner",
            to: "/competition/2023/announcement",
          },
        ],
      },
    ],
  },
];

export default navbarLinks;
