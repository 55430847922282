import React from "react";
import ReactMarkdown from "react-markdown";

import competitionDetails from "../../../../assets/markdowns/competition/2023/competition-details.md";
import { Paper } from "@mui/material";
import DefaultLayout from "../../../../components/layouts/default-layout";
import useDocTitle from "../../../../hooks/use-doc-title";
import useTracker from "../../../../hooks/use-tracker";
import Grid from "@mui/material/Grid";
import { QuickAccessCompetition2023 } from "../../../../components/quick-access-competition";
import baseStyle from "../../../../css/const";

export default function CompetitionDetails2023() {
  useDocTitle("Competition 2023 - Details");
  useTracker();

  const [markdown, setMarkdown] = React.useState("");
  const classes = baseStyle();

  React.useEffect(() => {
    fetch(competitionDetails)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  const _mapProps = (props) => ({
    ...props,
    escapeHtml: false,
  });

  const Markdown = (props) => <ReactMarkdown {..._mapProps(props)} />;
  // d_{safe}() = \dfrac{v^2_{o}}{-2\left|a_o^{min}\right|} - \dfrac{v_e^2}{-2\left |a_e^{min}\right|} + v_e t_d
  return (
    <DefaultLayout>
      <Grid container direction={"row"} spacing={2}>
        <Grid item xs={12} md={10}>
          <Paper className={classes.paper}>
            <Markdown source={markdown} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={2}>
          <QuickAccessCompetition2023 />
        </Grid>
      </Grid>
    </DefaultLayout>
  );
}
